import { Controller } from 'stimulus'
import { gsap, Power4, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ["content", "link"]
  static values = {
    maxHeight: Number,
    textOpen: String,
    textClose: String
  }

  connect () {
    this.maxHeight = this.maxHeightValue || '120'
    this.textOpen = this.textOpenValue || 'En savoir plus'
    this.textClose = this.textCloseValue || 'Réduire'

    this.isOpen = false

    window.addEventListener('load', () => {
      this.initializeTweenLite()
    }, false)
  }

  initializeTweenLite () {
    if (this.contentTarget.offsetHeight > this.maxHeight) {
      gsap.to(this.contentTarget, { height: `${this.maxHeight}px`, overflow: 'hidden', duration: 0.2 })
      gsap.set(this.linkTarget, { opacity: '1', pointerEvents: 'auto' })
      this.linkTarget.innerHTML = this.textOpen
    } else {
      gsap.set(this.linkTarget, { display: 'none' })
    }
  }

  handleClickLink () {
    !this.isOpen ? this.openAnimation() : this.closeAnimation()
    this.isOpen = !this.isOpen
  }

  closeAnimation () {
    gsap.to(this.contentTarget, { height: this.maxHeight, duration: 0.5 })
    this.linkTarget.innerHTML = this.textOpen
  }

  openAnimation () {
    // eslint-disable-next-line
    const tl = new gsap.timeline()

    tl.to(this.contentTarget, { height: 'auto', ease: Power4.easeOut, duration: 0.5 })
    this.linkTarget.innerHTML = this.textClose
  }
}
