import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  static values = { pageTitle: String }

  connect () {
    super.connect()

    // When the accommodations index's search form is submitted, the currrent 'list' partial, which contains this controller,
    // is replaced by a new version sent back by the AccommodationsController by the 'replace' function below.
    // This trigger the instanciation of a new SearchController, followed by the execution of this function.
    // Here we check if the page's title has changed (this happens often as the latter contains the number of
    // accommodations corresponding to the search criteria) and if so, we replace it
    if (this.hasPageTitleValue && document.title !== this.pageTitleValue) {
      document.title = this.pageTitleValue
    }
  }

  replace (event) {
    super.replace(event)
    const [,, xhr] = event.detail

    history.pushState(null, null, xhr.responseURL)
  }
}
