import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'destinationInput',
    'rentalManagementLandingFieldset'
  ]

  connect () {
    this.toggleRentalManagementLandingFieldset()
  }

  toggleRentalManagementLandingFieldset () {
    if (!this.hasRentalManagementLandingFieldsetTarget) return

    const rentalRentalManagementLandingSelected = (this.destinationInputTarget.value === "rental_management_landing")

    this.rentalManagementLandingFieldsetTarget.hidden = !rentalRentalManagementLandingSelected
    this.rentalManagementLandingFieldsetTarget.disabled = !rentalRentalManagementLandingSelected
  }
}
