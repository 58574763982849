import { Controller } from 'stimulus'
import { gsap, TimelineLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class extends Controller {
  static targets = ['animationContainer', 'agent', 'tab']

  connect () {
    gsap.to(this.agentTargets, { opacity: 1, y: 0, duration: 0.5, ease: 'expo.out', delay: 0.2 })
  }

  applyFilter (event) {
    event.preventDefault()

    const currentTab = event.target
    const currentCategory = currentTab.dataset.category

    if (currentTab.classList.contains('active')) return

    this.toggleActiveTab(currentTab)

    const tl = new TimelineLite()

    tl.to(this.agentTargets, { opacity: 0, y: -20, duration: 0.5, ease: 'expo.out' })

    this.agentTargets.forEach(agent => {
      if (!currentCategory || agent.dataset.category === currentCategory) {
        tl.to(agent, { display: "block", duration: 0 })
      } else {
        tl.to(agent, { display: "none", duration: 0 })
      }
    })

    tl.to(this.agentTargets, { opacity: 1, y: 0, duration: 0.5, ease: 'expo.out' })
  }

  toggleActiveTab (currentTab) {
    this.tabTargets.forEach(tab => tab.classList.toggle('active', tab === currentTab))
  }
}
