import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  followRedirect (event) {
    const [,, xhr] = event.detail

    const location = xhr.getResponseHeader('location')
    if (location) {
      window.location = location
    }
  }
}
