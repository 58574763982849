// FUNCTION LOADER CONTROLER
// Execute only once the page loader //

export default () => {
  if (window.sessionStorage.sessionLoaderController === undefined) {
    window.sessionStorage.setItem('sessionLoaderController', true)
  } else {
    document.querySelector('.loader').style.display = 'none'
  }
}
