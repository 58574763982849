import AutocompleteController from '@controllers/autocomplete_controller'
import { template } from 'lodash'

export default class extends AutocompleteController {
  static targets = [
    'cityId',
    'destinationId',
    'listableId',
    'listableType',
    'tags',
    'tagTemplate'
  ]

  connect () {
    super.connect()

    if (this.hasTagsTarget) {
      this.resourceTemplate = template(this.tagTemplateTarget.innerHTML)
    }
  }

  selectCity (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(title, 'by_city_id', value)
    } else if (this.hasListableTypeTarget) {
      this.listableTypeTarget.value = 'Location::City'
      this.listableIdTarget.value = value
    } else {
      this.destinationIdTarget.value = null
      this.cityIdTarget.value = value
    }

    this.inputTarget.value = title
    this.closeResults()
  }

  selectDestination (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(title, 'by_destination_id', value)
    } else if (this.hasListableTypeTarget) {
      this.listableTypeTarget.value = 'Destination'
      this.listableIdTarget.value = value
    } else {
      this.cityIdTarget.value = null
      this.destinationIdTarget.value = value
    }

    this.inputTarget.value = title
    this.closeResults()
  }

  addTag (title, attribute, value) {
    const content = this.resourceTemplate({ title, attribute, value })

    this.tagsTarget.insertAdjacentHTML('beforeend', content)
  }

  removeTag (event) {
    event.stopImmediatePropagation()

    event.currentTarget.remove()
  }
}
