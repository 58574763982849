/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "stylesheets/application.sass"
import "stylesheets/tailwind.sass"

import 'intersection-observer'
import ImageObjectFit from "@utils/image-object-fit"
import Modale from "@utils/modale"
import ConstantHeight from "@utils/constant-height"
import FlashMessage from "@utils/flash-messages"
import Loader from "@utils/loader-controller"
import executeRecaptcha from "@utils/grecaptcha"

// Stimulus
import "@controllers"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage
window.executeRecaptcha = executeRecaptcha
window.Rails = Rails

window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', { bubbles: true, cancelable: true })
  window.dispatchEvent(event)
}

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new Modale()
  new ConstantHeight()
  new Loader()
})

// FIX : wrong width init slider
window.addEventListener('load', () => {
  window.dispatchEvent(new Event("resize"))
}, false)
