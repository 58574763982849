import Carousel from "@controllers/carousel_controller"

export default class extends Carousel {
  get defaultOptions () {
    return {
      slidesPerView: 1,
      effect: 'fade',
      speed: 300,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + this.slides[index].dataset.name + '</span>'
        }
      }
    }
  }
}
